import { reactive } from 'vue'

let loadPromises = {};
let loadResolves = {};
let loadTimer;

const doLoad = async () => {
    try {
        const ids = Object.keys(loadResolves);
        const resolves = loadResolves;
        loadResolves = {};

        const results = (await (await fetch(`${ store.locations.apiUrl }?id[]=${ ids.sort().join('&id[]=') }&locale=${ document.documentElement.lang }`)).json());
        const locations = store.locations.all;
        const idMap = locations.map(l => l.id);

        results.forEach((location) => {
            const key = idMap.indexOf(location.id);

            if (key === -1) {
                console.error(`location ${location.id} not found in all locations`);
                return;
            }

            locations[key] = Object.assign({}, locations[key], location);
            resolves[location.id](locations[key]);
        });

        store.locations.all = locations;
    } catch (err) {
        // Failed loading locations, possibly running in maintenance mode
        location.reload();
    }
};

const load = (id) => {
    if (loadPromises[id]) {
        return loadPromises[id];
    }

    clearTimeout(loadTimer);

    return loadPromises[id] = new Promise((resolve) => {
        loadResolves[id] = resolve;

        if (Object.keys(loadResolves).length >= 100) {
            doLoad();
        } else {
            loadTimer = setTimeout(() => {
                doLoad();
            }, 300);
        }
    });
}

const store = reactive({
    locations: {
        all: [],
        filtered: [],
        ready: false,
        highlight: null,

        apiUrl: null,

        load,

        get onMap () {
            let locations = store.locations.filtered;
            let nomap = 0;

            if (store.bounds) {
                locations = locations.filter((location) => {
                    if (location.nomap) {
                        nomap++;
                        return false;
                    }

                    return store.bounds.contains({
                        lat: location.coordinates[0],
                        lng: location.coordinates[1],
                    })
                });
            }

            // If all locations with coordinates are visible, also show locations without coordinates
            if (locations.length + nomap === store.locations.filtered.length) {
                return store.locations.filtered;
            }

            return locations;
        }
    },

    place: null,
    bounds: null,
    sortBy: 'city',
    sortAsc: true,
    drawer: false,

    cantonBounds: {}
});

export function createStore () {
    return {
        install: (app) => {
            app.config.globalProperties.$store = store;
        }
    }
}
